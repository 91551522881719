.main{
    background-color: #B7C6BE;
}


.container{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width:33%;
    column-width: 33%;
    padding: 0 12px;
    margin-top: 50px;
}

.image-card{
   width: 100%;
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

@media (max-width: 991px){
    .container{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    }
}

@media (max-width: 480px){
    .container{
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width:100%;
    column-width: 100%;
    }
}

.image{
    width: 100%;
}
.image-card:hover{
    filter: opacity(.8);
}
.tags{
    text-align: center;
    padding:20px 0px;
    background-color: #425C55;
    border-bottom: 1px solid #fff;
}

.tag{
    font-size: large;
    outline: none;
    border:none;
    color: #fff;
    margin: 0px 10px;
    background-color: transparent;
    cursor: pointer;
}

.active{
    font-weight: bold;
    border-bottom: 1px solid #fff;
}

.scroller{
    display: block;
    margin: 0 auto;
    width: 150px;
    box-shadow: 0px 0px 0px 2px #9fb4f2;
	background:linear-gradient(to bottom, red 5%, purple 100%);
	background-color:#7892c2;
	border-radius:20px;
	border:2px solid #4e6096;
	
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:12px 37px;
	
}

